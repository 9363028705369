import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import notificationAPI from "../../services/notification";
import ReactiveButton from "reactive-button";

function ContactModalButton({ buttonName }) {
  const [show, setShow] = useState(false);
  const [state, setState] = useState("idle");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = (event) => {
    event.preventDefault();

    setTimeout(() => {
      setState("success");
    }, 2000);

    const name = event.target.name.value;
    const email = event.target.email.value;
    const message = event.target.message.value;

    setState("loading");
    notificationAPI(name, email, message); // send an HTTP request

    setTimeout(() => {
      handleClose();
    }, 5000);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <div className="px-2">{buttonName}</div>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <div className="form-group py-2">
              <label htmlFor="name">Name</label>
              <input className="form-control" id="name" required />
            </div>
            <div className="form-group py-2">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="name@example.com"
                required
              />
            </div>
            <div className="form-group py-2">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" id="message" required />
            </div>
            <div className="form-group py-2">
              <ReactiveButton
                buttonState={state}
                idleText="Submit"
                loadingText="Submitting"
                successText="Thanks for being awesome! We have received your message"
                id="modal-switch"
                className="form-control btn btn-primary"
                type="submit"
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactModalButton;
