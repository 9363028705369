import React from "react";
import mainLogo from "../../img/logo.png";
import { Link } from "gatsby";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
function Footer() {
  return (
    <footer className="bg-gray-900">
      <div className="max-w-screen-xl px-4 pt-2  mx-auto sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 mt-16 lg:grid-cols-2 lg:gap-32">
          <div className="max-w-sm mx-auto lg:max-w-none">
            <img
              src={mainLogo}
              alt="MVP Tribe Logo"
              className="mx-auto bg-white rounded-full w-15 h-15"
            />

            <p
              className="mt-4 text-center font-sans text-white lg:text-left"
              
            >
              MVP Tribe is a registered trademark of Trusty Bytes Private
              Limited. The unit singularly focus on fast and smart development
              of small scope of work for individual subject matter experts and
              small and large enterprises.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-2 text-left font-sans lg:grid-cols-1 lg:text-left">
            <div className="text-decoration-none">
              <strong className="text-sm font-bold tracking-wide text-white text-decoration-none ">
                <Link to="/our-offerings" style={{ textDecoration: "none" }}>
                  Our Offerings
                </Link>
              </strong>
            </div>

            <div>
              <strong className="text-sm font-bold tracking-wide text-white ">
                <Link to="/about" style={{ textDecoration: "none" }}>
                  About
                </Link>
              </strong>
            </div>
        {/* Disabled as of Now */}
            {/* <div>
            <strong className="text-sm font-bold tracking-wide text-white ">
              Our Missions
            </strong>
  
           
          </div> */}
            <div>
              <strong className="text-sm font-bold tracking-wide text-white ">
                <Link to="/mvp-insights" style={{ textDecoration: "none" }}>
                  MVP Insights
                </Link>
              </strong>
            </div>
            <div className="flex justify-center gap-4 mt-6 text-gray-300 lg:justify-start">
              <a
                className="transition hover:opacity-75"
                href="https://www.facebook.com/MVP-Tribe-108289708594581"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Facebook </span>

                <FacebookIcon />
              </a>

              <a
                className="transition hover:opacity-75"
                href="https://www.instagram.com/mvptribe/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Instagram </span>

                <InstagramIcon />
              </a>

              <a
                className="transition hover:opacity-75"
                href="https://twitter.com/mvptribe"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Twitter </span>

                <TwitterIcon />
              </a>

              <a
                className="transition hover:opacity-75"
                href="https://www.linkedin.com/company/mvp-tribe/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> LinkedIn </span>

                <LinkedInIcon />
              </a>
            </div>
          </div>
        </div>

        <div className="pt-8 mt-10 border-t border-white/10">
          <p className="text-xs font-sans leading-relaxed text-center text-gray-300">
            © MVP Tribe 2022. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
