import axios from "axios";
import lambda from "../config/lambda.json";
async function smsServices(additionalInfo, encryptedAPI) {
  const body = `New Message from MVP Tribe
  
Name: ${additionalInfo.name}
Email: ${additionalInfo.email}
Message: ${additionalInfo.message}
    `;
  const smsdata = JSON.stringify({
    apiKey: encryptedAPI,
    application: "MVPTribe",
    phoneNumber: "+919514015234",
    message: {
      Text: body,
    },
  });

  axios({
    method: "post",
    url: lambda.smsAPI,
    data: smsdata,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      //handle error
      console.log(error);
    });
}
export default smsServices;
