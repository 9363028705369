import React from "react";
import Footer from "./utils/footer";
import Navbar from "./utils/navbar";

function Layout({ children, pageContext: { page } }) {
  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow w-full">
          <Navbar className="sticky top-0 w-full z-50" />
          <div style={{ marginBottom: "1%" }}></div>
          <main className="flex-grow mb-8">{children}</main>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default Layout;
