import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import './src/styles/index.css'
import Layout from './src/components/layout'
import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/styles.css'
import './src/styles/styles.min.css'
import './src/styles/animate.css'

const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

const wrapRootElement = ({ element }) => <MDXProvider>{element}</MDXProvider>

export { wrapPageElement, wrapRootElement }
