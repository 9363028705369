import React, { Fragment } from "react";
import { Link } from "gatsby";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Logo from "../../img/logo.png";
import ContactModalButton from "../utils/contactModal";

const resources = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Our Offerings",

    href: "/our-offerings",
  },
  {
    name: "About",
    href: "/about",
  },
];

export default function Navbar() {
  return (
    <Popover className="fixed top-0 bg-white w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-2 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-50 lg:flex-1 ">
            <a href="/">
              <span className="sr-only">MVP Tribe</span>
              <img className="w-auto h-16 sm:h-16 xl:h-20 lg:h-20 " src={Logo} alt="MVP Logo" />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-8 w-8 mx-4" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            {resources.map((links) => {
              return (
                <>
                  <Link
                    to={links.href}
                    key={links.name}
                    className="xl:text-lg text-lg font-sans font-medium text-gray-500 hover:text-gray-900 text-decoration-none"
                  >
                    {links.name}
                  </Link>
                </>
              );
            })}
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <ContactModalButton
              className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
              buttonName={"Contact"}
            />
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 inset-x-0  transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-2 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-16 w-auto" src={Logo} alt="MVP Tribe" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                {resources.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700 text-decoration-none"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div>
                <ContactModalButton
                  buttonName={"Contact"}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
