import emailServices from './emailService';
import smsServices from './smsService';
import encryptedAPI from './APIKeyEncryption';

const notificationAPI = (name, email, message) => {
  
    const additionalInfo = {
        
        application: 'user',
        name: name,
        email: email,
        message: message      
    }
   

    smsServices(additionalInfo,encryptedAPI()); //SMS Function Call
    emailServices(additionalInfo,encryptedAPI()); // Email Function Call

}
export default notificationAPI;