import axios from 'axios';
import lambdaLink from '../config/lambda.json';

async function emailServices(additionalInfo, encryptedAPI) {
    const data = JSON.stringify({
        // JSON Data to pass
        apiKey: encryptedAPI,
        application: additionalInfo.application,
        sourceEmail: 'notification@trustybytes.com',
        email: ['anish@trustybytes.com', 'rameez@trustybytes.com'],
        
        message: {
            Subject: 'New Message from MVP Tribe !',
            Text: `New Message from MVP Tribe
            Name: ${additionalInfo.name}
            Email: ${additionalInfo.email}
            Message: ${additionalInfo.message}
                `,

        },
        additionalInfo:{}
    });
    axios({
            method: "post",
            url: lambdaLink.emailAPI,
            data: data,
            headers: {
                "Content-Type": "application/json"
            },
        }).then( function (response){
            //console.log(response.data);
        })
        .catch(function(error) {
            //handle error
           // console.log(error);
           
        });


}
export default emailServices;